import { useEffect, useState, useRef } from "react";

import "azure-maps-control/dist/atlas.min.css";
import L from "leaflet";
import 'leaflet/dist/leaflet.css';

import { useExploreSelector } from "../../state/hooks";
import {
  useItemBoundsLayer,
  useMapDrawTools,
  useItemLayer,
} from "./hooks";

const mapContainerId: string = "viewer-map";

const ExploreMap = () => {
  const mapRef = useRef<L.Map | null>(null);
  const { center, zoom, showSidebar } = useExploreSelector(s => s.map);
  const [mapReady, setMapReady] = useState<boolean>(false);

  const defaultGlobalBounds: L.LatLngBounds = new L.LatLngBounds(
    new L.LatLng(-90, -180), // Southwest corner [latitude, longitude]
    new L.LatLng(90, 180)     // Northeast corner [latitude, longitude]
  );

  // Initialize the map
  useEffect(() => {

    if (!mapRef.current) {

      const map = L.map(mapContainerId, {
        maxBounds: defaultGlobalBounds,
        minZoom: 2.5,
        maxZoom: 15,
        zoom: 1
      }).setView(center, zoom);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors'
      }).addTo(map);

      setMapReady(true);
      mapRef.current = map;
    }
  }, [
    center,
    zoom,

  ]);

  useEffect(() => {
    const elNames = ["#atlas-map-shortcuts", "#atlas-map-style", "#atlas-map-state"];
    elNames.forEach(elName => {
      const els = document.querySelectorAll(elName);
      if (els.length === 2) {
        els[1].remove();
      }
    });
  }, [mapReady]);

  useItemBoundsLayer(mapRef, mapReady);
  useMapDrawTools(mapRef, mapReady);
  useItemLayer(mapRef, mapReady);

  // Class used to sync state via responsive media queries in css
  const visibilityClass = !showSidebar ? "explorer-sidebar-hidden" : "";

  return (
    <div className={`explorer-map ${visibilityClass}`} style={mapContainerStyle}>

      <div id={mapContainerId} style={mapElementStyle} />
    </div>
  );
};

export default ExploreMap;

const mapContainerStyle: React.CSSProperties = {
  width: "100%",
  height: "100%",
  position: "relative",
};

const mapElementStyle = { width: "100%", height: "100%" };
