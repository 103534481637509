export const cardStyles = {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    display: 'inline-block',
    width: 400,
    height: 500,
    marginLeft: "2.75em", marginRight: "2.75em", marginBottom: "2em", marginTop: "5em",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
    transform: "scale(1)", 
};

export const cardHoverStyles = {
    transform: "scale(1.05)",
    boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)"
};

export const imageContainerStyles = {
    width: "100%",
    height: 300,
    overflow: "hidden",
    borderRadius: "10px 10px 0 0",
};


export const imageStyles = {
    width: "100%",
    height: "100%",
};

export const buttonStyles = {
    position: "absolute" as "absolute",
    top: "25%",
    left: "50%",
    bottom: "50%",
    transform: "translate(-50%, -50%)",
    display: "none",
    width: "40%",
    textAlign: "left" as "left",
    height: 50,
    border: 0,
    backgroundColor: "#083a59",
    color: "#fff",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "opacity 0.3s ease",
};
