import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";

import { IStacItem } from "types/stac";
import { HUB_URL } from "./constants";

dayjs.extend(utc);
export { dayjs };

// The represented date without consideration for the timezone
export const toAbsoluteDate = (date: Dayjs) => {
  return new Date(date.year(), date.month(), date.date());
};

export const capitalize = (value: string) => {
  const str = String(value);
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const titleCase = (str: string, splitChar: string = " ") => {
  return str.split(splitChar).map(capitalize).join(" ");
};

export const isort = (a: string, b: string) =>
  a.localeCompare(b, undefined, { sensitivity: "base" });

const sortAlphaByKey = (key: string) => {
  return (a: any, b: any) => {
    const al = a[key].toLowerCase();
    const bl = b[key].toLowerCase();

    if (al < bl) {
      return -1;
    }
    if (al > bl) {
      return 1;
    }
    return 0;
  };
};

// Alpha sort, except favor special terms to the beginning
export const sortSpecialByKey = (key: string) => {
  const specialKeys = ["landsat", "sentinel"];
  const isSpecial = (val: any) =>
    specialKeys.some(term => val[key]?.toLowerCase()?.includes(term));

  return (a: any, b: any) => {
    const aSpecial = isSpecial(a);
    const bSpecial = isSpecial(b);

    if (aSpecial && bSpecial) {
      return sortAlphaByKey(key)(a, b);
    } else if (aSpecial && !bSpecial) {
      return -1;
    } else if (!aSpecial && bSpecial) {
      return 1;
    }
    return sortAlphaByKey(key)(a, b);
  };
};

export const sortByLookup = (lookup: any) => {
  const get = (k: string) => lookup[k] ?? 50;

  return (a: string, b: string) => {
    if (get(a) < get(b)) {
      return -1;
    }
    if (get(a) > get(b)) {
      return 1;
    }
    return 0;
  };
};

export const sortByPosition = (list: any) => {
  return sortByLookup(
    Object.fromEntries(list.map((item: any, idx: number) => [item, idx]))
  );
};

interface ILauncherConfig {
  repo: string;
  branch: string;
  filePath: string;
}

const configFromLauncher = (launcher: ILauncherConfig | string): ILauncherConfig => {
  let config: ILauncherConfig;
  if (typeof launcher === "string") {
    config = {
      branch: "main",
      filePath: launcher,
      repo: "https://github.com/microsoft/PlanetaryComputerExamples",
    };
  } else {
    config = launcher;
  }
  return config;
};

export function buildHubLaunchUrl(filePath: string): string;
export function buildHubLaunchUrl(launchConfig: ILauncherConfig): string;
export function buildHubLaunchUrl(launcher: ILauncherConfig | string): string {
  const { repo, branch, filePath } = configFromLauncher(launcher);
  const urlRepo = encodeURIComponent(repo);
  const urlBranch = encodeURIComponent(branch);
  const repoName = repo.split("/").pop();

  // Get a unique but arbitrary string for the workspace path. This works
  // around in issue where nbgitpuller workspace may conflict with JupyterHub.
  // The workspace can't contain / so substitute a - for any.
  const fileWorkspace = filePath
    .substring(filePath.indexOf("/") + 1, filePath.lastIndexOf("."))
    .replace(/\//g, "-");

  const pathPrefix = filePath.endsWith(".ipynb")
    ? `lab/workspaces/${fileWorkspace}/tree`
    : "rstudio";

  const urlPath = encodeURIComponent(`${pathPrefix}/${repoName}/${filePath}`);

  return `${HUB_URL}/user-redirect/git-pull?repo=${urlRepo}&urlpath=${urlPath}&branch=${urlBranch}`;
}

export function buildGitHubUrl(launcher: ILauncherConfig): string;
export function buildGitHubUrl(launcher: string): string;
export function buildGitHubUrl(launcher: ILauncherConfig | string): string {
  const { repo, branch, filePath } = configFromLauncher(launcher);
  return `${repo}/blob/${branch}/${filePath}`;
}

/*
  For markup that was generated from external tools (marked, nbconvert, etc) run through
  some DOM manipulations to alter the structure for accessibility reasons
*/
export const a11yPostProcessDom = (dom: Document) => {
  // Find img tags without an alt tag, and make it a presentation role
  dom
    .querySelectorAll("img:not([alt]")
    .forEach(el => el.setAttribute("role", "presentation"));

  // Look for anchor tags with only an image inside, and make give the anchor
  // a title attribute
  dom.querySelectorAll("a > img").forEach(el => {
    el.parentElement?.setAttribute("title", "Link to image content");
  });

  // Look for .dataframe with an empty header and add the text id. It may
  // also have a value on the next header row, so remove that as well.
  dom
    .querySelectorAll(".dataframe > thead > tr > th:nth-child(1)")
    .forEach(header => {
      if (header.textContent === "") {
        const nextHeader =
          header.parentElement?.nextElementSibling?.firstElementChild;
        if (nextHeader && nextHeader.textContent) {
          header.textContent = nextHeader.textContent;
          nextHeader.parentElement?.remove();
        } else {
          header.textContent = "row_id";
        }
      }
    });

  // Keyboard users needs a tabindex set on scrollable content if they
  // otherwise do not have focusable content. These python codeblocks are
  // brought over from nbconvert and must have a tabindex set to all keyboard
  // scrolling.
  dom.querySelectorAll(".highlight pre").forEach(el => {
    el.setAttribute("tabindex", "0");
  });

  // <p> tags with role="heading" need an aria-level attribute
  dom
    .querySelectorAll("p[role=heading]")
    .forEach(el => el.setAttribute("aria-level", "3"));
};

export const scrollToHash = (
  elementId: string,
  behavior: ScrollBehavior = "smooth"
) => {
  // Remove the hash
  const id = elementId.substring(elementId.lastIndexOf("#") + 1);
  const el = document.getElementById(id);

  if (el) {
    el.scrollIntoView({ behavior: behavior });
  }
};

export const useItemPreviewUrl = (
  item: IStacItem
) => {
  
  var placeholder = "/images/no_photo_available.jpg"
  var hasTMB = Object.keys(item.assets).includes("thumbnail");

  if (hasTMB) {
    return item.assets["thumbnail"].href
  } else {
    return placeholder
  }
};