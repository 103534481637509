import React, { createContext, useContext, useState, useEffect } from 'react';

const defaultConfig = {
  stacRoot: "https://stac.eodc.eu/api/v1",
  tiTiler: "https://titiler.services.eodc.eu"
};

const ConfigContext = createContext({
  config: defaultConfig,
  setConfig: () => {},
});

export const useConfig = () => useContext(ConfigContext);

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(defaultConfig);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const loadConfig = async () => {
      try {
        const response = await fetch('/config.json');
        const configData = await response.json();
        setConfig(configData);
      } catch (error) {
        console.error('Error loading config:', error);
      } finally {
        setIsLoaded(true);
      }
    };

    loadConfig();
  }, []);

  return (
    <ConfigContext.Provider value={{ config, setConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};