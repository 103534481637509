import { Text, Icon, Separator } from "@fluentui/react";
import Layout from "components/Layout";

import HomeBanner from "./components/HomeBanner";
import { separator } from "./styles"


export const Home = () => {
  return (
    <>
      <Layout onGrid={false} allowAnnouncement={false} >
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
          <HomeBanner/>
          <Text
            block
            variant="large"
            className="grid-content"
            style={{ margin: "2.8rem 0", textAlign: "left", maxWidth: "815px", color: "#083a59", fontFamily: "markOT, system-ui, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important", fontWeight: "600" }}
          >
            By translating complex datasets into comprehensible information, we are
            instrumental in crafting valuable and cutting-edge solutions. Cultivating
            sustainable relationships is in our DNA, providing the foundation for the
            creation of impactful and user-friendly services.
          </Text>
          <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              margin: "20px"
            }}>
                <div style={{
                  flex: "1",
                  padding: "10px",
                  margin: "0 10px",
                  maxWidth: "30%", 
                }}>
                  <Icon iconName="MapLayers" style={{ fontSize: "25px", color: "#169eb0" }} />
                  <h3 style={{ color: "#083a59" }}>Data Catalog</h3>
                  <Text
                    block
                    variant="medium"
                    className="grid-content"
                    style={{ margin: "1.8rem 0", textAlign: "left", maxWidth: "815px", fontFamily: "markOT, system-ui, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important", fontWeight: "300" }}
                  >
                  Explore our Data Catalog for high-resolution Earth observation data, including detailed satellite imagery and climate trends. Empower your research and innovation with comprehensive insights into environmental and urban changes.
                  </Text>
              </div>

              <Separator
                vertical
                className={separator}
              />

              <div style={{
                flex: "1",
                padding: "10px",
                alignContent: "center",
                margin: "0 10px",
                maxWidth: "30%",
              }}>
                <Icon iconName="Globe" style={{ fontSize: "25px", color: "#169eb0" }} />
                <h3 style={{ color: "#083a59" }}>API</h3>
                <Text
                  block
                  variant="medium"
                  className="grid-content"
                  style={{ margin: "1.8rem 0", textAlign: "left", fontFamily: "markOT, system-ui, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important", fontWeight: "300" }}
                >
                  Discover our suite of API services, designed to streamline and enhance your applications with powerful, easy-to-integrate solutions. From data access to advanced functionalities, our APIs offer the tools you need to build and innovate effortlessly.
                </Text>
              </div>

              <Separator
                vertical
                className={separator}
              />
              <div style={{
                flex: "1",
                padding: "10px",
                textAlign: "center",
                margin: "0 10px",
                maxWidth: "30%",
              }}>
                <Icon iconName="WebAppBuilderFragmentCreate" style={{ fontSize: "25px", color: "#169eb0" }} />
                <h3 style={{ color: "#083a59" }}>Applications</h3>
                <Text
                  block
                  variant="medium"
                  className="grid-content"
                  style={{ margin: "1.8rem 0", textAlign: "left", fontFamily: "markOT, system-ui, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important", fontWeight: "300" }}
                >
                  Explore our suite of applications, crafted to simplify and enhance your workflows with intuitive, user-friendly interfaces. Empower your productivity and innovation with tools designed to meet your diverse needs.
                </Text>
              </div>

            </div>
        </div>
      </Layout>
    </>
  );
};
