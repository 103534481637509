import { Text } from "@fluentui/react";

import { IStacCollection } from "types/stac";
import LabeledValue from "../controls/LabeledValue";

import { Dayjs } from "dayjs";
import { dayjs } from "utils";

export const parseDatetime = (date: Date | string | Dayjs) => {
  return dayjs(date).utc();
};


export const formatDateShort = (date: Date | string | Dayjs) => {
  const d = parseDatetime(date);
  return d.format("MM/DD/YYYY");
};

interface TemporalExtentProps {
  extent: IStacCollection["extent"]["temporal"];
  label?: string;
}

const TemporalExtent: React.FC<TemporalExtentProps> = ({
  extent,
  label = "Temporal Extent",
}) => {
  const formatted = extent.interval.map((period, idx) => {
    const [start, end] = period;

    const startFormat = formatDateShort(start as string);
    const endFormat = end ? formatDateShort(end) : "Present";
    return (
      <Text block key={`temporal-${idx}`}>{`${startFormat} – ${endFormat}`}</Text>
    );
  });

  return <LabeledValue label={label}>{formatted}</LabeledValue>;
};

export default TemporalExtent;
