import {
  FontSizes,
  FontWeights,
  IStackStyles,
  IStackTokens,
  ITextStyles,
  Stack,
  Text,
} from "@fluentui/react";
import { useExploreSelector } from "pages/Explore/state/hooks";
import { selectCurrentCollection } from "pages/Explore/state/collectionSlice";

import ResetSelectors from "./ResetSelectors";

export const TitleHeader = () => {
  const { collection } = useExploreSelector(selectCurrentCollection);
  const commandBar = (
    <Stack
      horizontal
      horizontalAlign="end"
      tokens={stackTokens}
      styles={commandBarStyles}
    >
      <ResetSelectors />
    </Stack>
  );
  return (
    <Stack horizontal styles={stackStyles}>
      <Text styles={sidebarTitleStyles} block>
        Explore datasets
      </Text>
      {collection && commandBar}
    </Stack>
  );
};

const stackTokens: IStackTokens = {
  childrenGap: 5,
};
const stackStyles: Partial<IStackStyles> = {
  root: {
    justifyContent: "space-between",
  },
};
const sidebarTitleStyles: Partial<ITextStyles> = {
  root: {
    padding: "5px 0",
    fontSize: FontSizes.mediumPlus,
    fontWeight: FontWeights.bold,
  },
};
const commandBarStyles: Partial<IStackStyles> = {
  root: {
    marginRight: 2,
  },
};
