import { BBox, Feature, GeoJsonProperties, MultiPolygon, Polygon } from "geojson";
import bboxToPolygon from "@turf/bbox-polygon";
import union from "@turf/union";
import { IStacAsset, IStacCollection } from "types/stac";
import collections from "config/datasets.yml";

export const spatialExtentToMultipolygon = (bbox: number[][]) => {
  const polys = bbox.map(box => bboxToPolygon(box as BBox));

  const multiPoly = polys.reduce<Feature<
    Polygon | MultiPolygon,
    GeoJsonProperties
  > | null>((unionedPoly, currPoly) => {
    if (unionedPoly) {
      return union(unionedPoly.geometry, currPoly.geometry);
    }
    // base case
    return currPoly;
  }, null);

  return multiPoly;
};

export const isValidExplorer = (collection: IStacCollection) => {
  // By default, all collections with at least one GeoTIFF data-role item_asset
  // are renderable. Collections can be restricted from explorer via a hideInExplorer
  // attribute in the dataset config.

  // NOTE NOTE we want to add item_assets to our stac collections so that this bit of code will work
  // for all of our collections
  if (collection.item_assets) {
    var hasIMG = !!Object.values(collection.item_assets).find(a =>
      a.type?.toLowerCase().includes("geotiff")
    );
    if (!hasIMG) {
      hasIMG = !!Object.values(collection.item_assets).find(a =>
        a.media_type?.toLowerCase().includes("jp2")
      );
    }

    const isHidden = Boolean(collections[collection.id]?.hideInExplorer);
    const hasCollectionTileJson = !!Object.values(collection.assets || {}).find(a =>
      a.roles?.includes("tiles")
    );

    return (hasIMG || hasCollectionTileJson) && !isHidden;
  } else if (collection.summaries?.item_assets) {
   
    const itemAssets: Record<string, IStacAsset> = collection.summaries.item_assets;
    var hasIMG = !!Object.values(itemAssets).find(asset =>
      asset.type?.toLowerCase().includes("geotiff")
    );
    const isHidden = Boolean(collections[collection.id]?.hideInExplorer);
    const hasCollectionTileJson = !!Object.values(collection.assets || {}).find(a =>
      a.roles?.includes("tiles")
    );

    return (hasIMG || hasCollectionTileJson) && !isHidden;
  }
  return true;
};
