import { IStackTokens, Stack } from "@fluentui/react";
import { ErrorBoundary } from "react-error-boundary";

import CustomQueryBuilder from "../selectors/CustomQueryBuilder";
import ErrorFallback, { handleErrorBoundaryError } from "components/ErrorFallback";
import {
  CatalogSelector,
} from "../selectors";

const SelectorPane = () => {

  const contents = (
    <>
      <CatalogSelector />
      <CustomQueryBuilder />
    </>
  );
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={handleErrorBoundaryError}
    >
      <Stack tokens={stackTokens}>{contents}</Stack>
    </ErrorBoundary>
  );
};

export default SelectorPane;

const stackTokens: IStackTokens = {
  childrenGap: 6,
};
