import { useState, useRef, useEffect} from 'react';

import { dropDownButton, dropDownDiv, dropDownStack, globalHeaderStyle, logoButton } from "./styles";
import { DefaultButton, Stack, FocusZone } from '@fluentui/react';

const Header = ({ onGrid = true }) => {
  const [iconName, setIconName] = useState('GlobalNavButton');
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const onButtonClick = () => {

    if (isAnimating) return;

    setIsOptionsVisible(!isOptionsVisible);
    setIsAnimating(true);
    setIconName(prevIcon => prevIcon === 'GlobalNavButton' ? 'Remove' : 'GlobalNavButton');


    if (dropdownRef.current) {
      setTimeout(() => {
        if (isOptionsVisible) {
          dropdownRef.current!.style.height = '0px';
          dropdownRef.current!.style.opacity = '0';
        } else {
          dropdownRef.current!.style.height = dropdownRef.current!.scrollHeight + 'px';
          dropdownRef.current!.style.opacity = '1';
        }
      }, 10);
    }
  };

 
  const onTransitionEnd = () => {
    setIsAnimating(false);
    if (!isOptionsVisible && dropdownRef.current) {
      dropdownRef.current.style.height = '0px';
    }
  };

  const handleNavigation = (url: string) => {
    window.location.href = url;
  };

  useEffect(() => {
    if (dropdownRef.current && isOptionsVisible) {
      dropdownRef.current.style.height = dropdownRef.current.scrollHeight + 'px';
      dropdownRef.current.style.opacity = '1';
    }
  }, [isOptionsVisible]);

  return (
    <div>
      <header className={globalHeaderStyle}>
        <DefaultButton iconProps={{ iconName }}  className={logoButton} onClick={onButtonClick} style={{ zIndex: 1 }} />
        <img
          alt=""
          src="/images/eodc-logo-panel3.svg"
          role="presentation"
          aria-hidden="true"
          style={{
            position: "relative",
            zIndex: 1
          }}
        />
        <DefaultButton iconProps={{ iconName: 'Search' }} className={logoButton} />
      </header>
      <div
        ref={dropdownRef}
        className={dropDownDiv}
        style={{
          height: isOptionsVisible ? 'auto' : '0px',
          opacity: isOptionsVisible ? 1 : 0,
        }}
        onTransitionEnd={onTransitionEnd}
      >
        <FocusZone>
          <Stack tokens={{ childrenGap: 0 }} className={dropDownStack}>
          <DefaultButton
              text="Service Catalogue"
              onClick={() => handleNavigation('/')}
              className={dropDownButton}
              styles={{
                rootHovered: { backgroundColor: '#ffff',  color: '#083a59' }
              }}
            />
            <DefaultButton
              text="Explore"
              onClick={() => handleNavigation('/explore')}
              className={dropDownButton}
              styles={{
                rootHovered: { backgroundColor: '#fff',  color: '#083a59' }
              }}
            />
            <DefaultButton
              text="Data Catalogue"
              onClick={() => handleNavigation('/catalogue')}
              className={dropDownButton}
              styles={{
                rootHovered: { backgroundColor: '#fff',  color: '#083a59' }
              }}
            />
            <DefaultButton
              text="Gallery"
              onClick={() => handleNavigation('/gallery')}
              className={dropDownButton}
              styles={{
                rootHovered: { backgroundColor: '#fff',  color: '#083a59' }
              }}
            />
            <DefaultButton
              text="Documentation"
              onClick={() => handleNavigation('/docs')}
              className={dropDownButton}
              styles={{
                rootHovered: { backgroundColor: '#fff',  color: '#083a59' }
              }}
            />
          </Stack>
        </FocusZone>
      </div>
    </div>
  );
};

export default Header;
