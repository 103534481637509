// Note that enums can't be exported from a types.d.ts file, so must have their own.

export enum SidebarPanels {
  itemSearch = "itemSearch",
}

export enum LayerType {
  tile = "raster-tile",
  polygon = "vt-polygon",
}
