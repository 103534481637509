import { mergeStyles } from "@fluentui/react";

export const logoButton = mergeStyles({
  "margin": '3em',
  "border": 'none',
  "color": '#737b8d',
  "transform": 'scaleX(-1)',
  "zIndex": 1,
  selectors: {
    ':hover .ms-Button-icon': {
      color: '#737b8d',
    },
    '.ms-Button-icon': {
      color: '#737b8d',
      fontSize: '24px'
    },
  },
});

export const globalHeaderStyle = mergeStyles({
  backgroundColor: 'var(--white) !important',
  color: 'var(--grey) !important',
  height: '7rem',
  display: 'flex',
  justifyContent: 'space-between',
});

export const dropDownDiv = mergeStyles({
  position: 'absolute',
  width: "100%",
  zIndex: '1001',
  overflow: 'hidden',
  transition: 'height 0.3s ease, opacity 0.3s ease'
});

export const dropDownStack = mergeStyles({
  root: { 
    width: '100vw' 
}});

export const dropDownButton = mergeStyles({
    height: '2.5rem !important',
    width: '100vw',
    background: 'rgb(248, 247, 247)',
    color: '#737b8d',
    textAlign: 'left',
    border: 0,
    padding: '0 4rem !important',
    fontSize: '16px !important',
    filter: 'drop-shadow(0px 2px 3px rgb(22, 158, 176, 0.35))'
});
