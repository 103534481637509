import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { uniqueId } from "lodash-es";

import { IStacCollection } from "types/stac";
import { LayerType } from "../enums";
import {
  ILayerState,
  ICollection,
  ICollectionRenderOption,
  ICollectionState,
} from "../types";
import { DEFAULT_MIN_ZOOM } from "../utils/constants";
import { CqlExpressionParser } from "../utils/cql";
import { CqlExpression, ICqlExpressionList } from "../utils/cql/types";
import { getCurrentCollectionDraft } from "./helpers";
import { AppThunk, ExploreState } from "./store";


export const initialCollectionState: ICollection = {
    name: null,
    description: null,
    cql: [],
    sortby: "desc",
    searchId: null,
  };
  
export const initialLayerState: ILayerState = {
    layerId: "",
    collection: null,
    query: initialCollectionState,
    isCustomQuery: false,
    isPinned: false,
    renderOption: null,
    layer: {
        minZoom: DEFAULT_MIN_ZOOM,
        maxExtent: [],
        opacity: 100,
        visible: true,
    },
};

const initialState: ICollectionState = {
    layers: {},
    layerOrder: [],
    currentEditingLayerId: null,
    isLoadingInitialState: true,
  };


export const collectionSlice = createSlice({
    name: "collection",
    initialState,
    reducers: {
        setCurrentEditingLayerId: (state, action: PayloadAction<string>) => {
        state.currentEditingLayerId = action.payload;
        },

        setCollection: (state, action: PayloadAction<IStacCollection>) => {
        // When setting a new collection, remove the currently edited mosaic layer
        // and the search order, if it is not pinned
        const currentCollection = getCurrentCollectionDraft(state);
        if (!currentCollection.isPinned) {
            state.currentEditingLayerId &&
            delete state.layers[state.currentEditingLayerId];
            state.layerOrder = state.layerOrder.filter(
            id => id !== state.currentEditingLayerId
            );
        }

        state.currentEditingLayerId = uniqueId(action.payload.id);
        state.layers[state.currentEditingLayerId] = {
            ...initialLayerState,
            collection: action.payload,
            layerId: state.currentEditingLayerId,
        };
        state.layerOrder = [state.currentEditingLayerId].concat(state.layerOrder);
        },

        setQuery: (state, action: PayloadAction<ICollection>) => {
          const collection = getCurrentCollectionDraft(state);
          const query = { ...action.payload, searchId: null };
          collection.query = query;
        },

        setIsCustomQuery: (state, action: PayloadAction<boolean>) => {
          const collection = getCurrentCollectionDraft(state);
          collection.isCustomQuery = action.payload;
          collection.query = initialCollectionState;
        },

        addOrUpdateCustomCqlExpression: (
          state,
          action: PayloadAction<CqlExpression>
        ) => {
          const collection = getCurrentCollectionDraft(state);
          const draft = collection.query.cql;
          const newExpProperty = new CqlExpressionParser(action.payload).property;
          const existingIndex = draft.findIndex(
            (exp: any) => new CqlExpressionParser(exp).property === newExpProperty
          );
    
          if (existingIndex === -1) {
            draft.splice(draft.length, 0, action.payload);
          } else {
            draft.splice(existingIndex, 1, action.payload);
          }
        },

        removeCustomCqlProperty: (state, action: PayloadAction<string>) => {
          const collection = getCurrentCollectionDraft(state);
          const draft = collection.query.cql;
          const property = action.payload;
          const existingIndex = draft.findIndex(
            (exp: any) => new CqlExpressionParser(exp).property === property
          );

          if (existingIndex > -1) {
            draft.splice(existingIndex, 1);
          }
        },

        removeLayerById: (state, action: PayloadAction<string>) => {
          const layerId = action.payload;
          if (layerId in state.layers) {
            delete state.layers[layerId];
            state.layerOrder = state.layerOrder.filter(id => id !== layerId);
          }
        },

        setRenderOption: (state, action: PayloadAction<ICollectionRenderOption>) => {
          const mosaic = getCurrentCollectionDraft(state);
          const renderOption = Object.assign(
            { minZoom: DEFAULT_MIN_ZOOM },
            action.payload
          );
    
          if (action.payload.minZoom !== undefined) {
            mosaic.layer.minZoom = action.payload.minZoom;
          }
    
          // Backwards compatibility for old render options
          if (!renderOption.type) {
            renderOption.type = LayerType.tile;
          }
    
          mosaic.renderOption = renderOption;
        },
    },
});
  
export const {
    setCollection,
    setQuery,
    setIsCustomQuery,
    addOrUpdateCustomCqlExpression,
    removeLayerById,
    setRenderOption
} = collectionSlice.actions;

// Custom selector to get the current mosaic CQL query
export const selectCurrentCql = (state: ExploreState) => {
    const mosaic = selectCurrentCollection(state);
    return mosaic?.query?.cql || [];
  };

// Custom selector to get the current mosaic layer being edited / displayed
export const selectCurrentCollection = (state: ExploreState): ILayerState => {
    if (
    !state.collection.currentEditingLayerId ||
    !state.collection.layers[state.collection.currentEditingLayerId]
    ) {
    return initialLayerState;
    }
    return state.collection.layers[state.collection.currentEditingLayerId];
};

export const setCollectionQuery = createAsyncThunk<string, ICollection>(
  "cql-api/registerQuery",
  async (queryInfo: ICollection, { getState, dispatch }) => {
    // Update the new mosaic info into state
    dispatch(setQuery(queryInfo));

    const state = getState() as ExploreState;
    const cql = selectCurrentCql(state);

    return cql;
  }
);

// Register the new CQL query and set the resulting searchId
async function registerUpdatedSearch(getState: () => unknown) {
  const state = getState() as ExploreState;

  const cql = selectCurrentCql(state);

  return cql;
}

export const setCustomCqlExpressions = createAsyncThunk<string, CqlExpression>(
  "cql-api/registerCustomQuery",
  async (
    cqlExpression: CqlExpression | ICqlExpressionList,
    { getState, dispatch }
  ) => {
    const expressions = Array.isArray(cqlExpression)
      ? cqlExpression
      : [cqlExpression];

    expressions.forEach(expression => {
      dispatch(addOrUpdateCustomCqlExpression(expression));
    });

    return await registerUpdatedSearch(getState);
  }
);

export default collectionSlice.reducer;

export const resetCollectionState =
  (layerId: string): AppThunk =>
  dispatch => {
    dispatch(removeLayerById(layerId));
  };