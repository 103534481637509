import { useQuery } from "react-query";
import $RefParser, { JSONSchema } from "@apidevtools/json-schema-ref-parser";

export const useCollectionQueryables = (collectionId: string | undefined, appConfig: { stacRoot: string }) => {
  return useQuery(
    ["queryable", collectionId],
    async () => getCollectionQueryables(collectionId, appConfig),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      enabled: Boolean(collectionId),
    }
  );
};

const getCollectionQueryables = async (
  collectionId: string | undefined,
  appConfig: { stacRoot: string } 
): Promise<JSONSchema> => {

  const schema = await $RefParser.dereference(
    `${appConfig.stacRoot}/collections/${collectionId}/queryables`
  );

  return schema;
};
