export const SAS_URL = "https://stac.eodc.eu/api/v1"

export const IMAGE_URL = "https://stac.eodc.eu/api/v1"
export const HUB_URL = "https://stac.eodc.eu/api/v1"
export const AUTH_URL = "https://stac.eodc.eu/api/v1"

export const AZMAPS_CLIENT_ID = ""

export const X_REQUEST_ENTITY = "X-PC-Request-Entity";
export const QS_REQUEST_ENTITY = "request_entity";
export const REQUEST_ENTITY = "explorer";

export const MOBILE_WIDTH = 430;
