import { useEffect } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS
import { useExploreSelector } from "pages/Explore/state/hooks";
import {
  itemLineLayer,
  itemOutlineLayer,
} from "../../../utils/layers";


const useItemBoundsLayer = (
  mapRef: React.MutableRefObject<L.Map | null>,
  mapReady: boolean
) => {
  const {
    map: { boundaryShape },
    detail,
  } = useExploreSelector(s => s);

  const boundaryPoly = boundaryShape ?? detail.selectedItem?.geometry;
  const { display } = detail;

  useEffect(() => {
    const map = mapRef.current;


    if (!mapReady || !map ) {
      return
    } else {

      // Add layers to the map
      map.addLayer(itemLineLayer);
      map.addLayer(itemOutlineLayer);
    }
    return () => {
      // Remove layers when component unmounts
      map.removeLayer(itemLineLayer);
      map.removeLayer(itemOutlineLayer);
    };
  }, [mapRef, mapReady]);

  // Zoom to the bounds of the selected item when it is added to the map
  useEffect(() => {

    if (!boundaryPoly || !detail.selectedItem) return;

    const bbox = detail.selectedItem.bbox

    if (bbox) {
      var bounds = L.latLngBounds(
        L.latLng(bbox[1], bbox[0]), // Southwest corner (latitude, longitude)
        L.latLng(bbox[3], bbox[2])  // Northeast corner (latitude, longitude)
      );

      if (display.showSelectedItemAsLayer && display.zoomToItem) {
        mapRef.current?.fitBounds(bounds, { padding: [100, 100] });
      }
    }
    itemOutlineLayer.clearLayers();

  }, [mapRef, boundaryPoly, display.showSelectedItemAsLayer, display.zoomToItem]);
};

export default useItemBoundsLayer;
