import axios from "axios";
import { QueryFunctionContext, useQuery } from "react-query";

import { IStacCollection } from "types/stac";

export const pcApiClient = axios.create({
});

// Query content can be prefetched if it's likely to be used
export const usePrefetchContent = () => {
  // no-op
};

export const useCollections = (stacRoot: string) => {
  return useQuery(
    ["stac-collections"],
    async () => getCollections({} as QueryFunctionContext<["stac-collections"]>, stacRoot),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};

export const useStaticMetadata = (staticFileName: string) => {
  return useQuery(["static-file", staticFileName], getStaticMetadata);
};

export const useGitHubDatasetDescription = (datasetId: string) => {
  return useQuery(
    ["github-dataset-description", datasetId],
    getGithubDatasetDescription,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
    }
  );
};


const getCollections = async (context: QueryFunctionContext<["stac-collections"]>, stacRoot: string): Promise<{ collections: IStacCollection[] }> => {

  const resp = await pcApiClient.get(`${stacRoot}/collections`);

  return { ...resp.data, collections: resp.data.collections };
};

const getStaticMetadata = async (
  queryParam: QueryFunctionContext<[string, string]>
) => {
  const [, file] = queryParam.queryKey;
  const resp = await axios.get(`static/metadata/${file}`);
  return resp.data;
};

const getGithubDatasetDescription = async (
  queryParam: QueryFunctionContext<[string, string]>
): Promise<string | null> => {
  const [, datasetId] = queryParam.queryKey;
  const resp = await axios.get(
    `https://raw.githubusercontent.com/microsoft/AIforEarthDataSets/main/data/${datasetId}.md`
  );

  const text = resp.data as string;
  try {
    const overviewStart = text.indexOf("## Overview");
    return text.substring(overviewStart);
  } catch {
    return null;
  }
};

