import { WritableDraft } from "immer/dist/internal";
import { createDraft } from "immer";
import { ICollectionState } from "../types";
import { initialLayerState } from "./collectionSlice";

// Get the current mosaic info as an immer draft object
export const getCurrentCollectionDraft = (state: WritableDraft<ICollectionState>) => {
  if (!state.currentEditingLayerId || !state.layers[state.currentEditingLayerId]) {
    return createDraft(initialLayerState);
  }

  return state.layers[state.currentEditingLayerId];
};
