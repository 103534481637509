import { QueryFunctionContext, useQuery, UseQueryResult } from "react-query";
import { IStacFilter, IStacSearchResult } from "types/stac";
import { pcApiClient } from "utils/requests";


export const useStacSearch = (
  search: IStacFilter | undefined,
  appConfig: { stacRoot: string },
  enabled?: boolean
): UseQueryResult<IStacSearchResult, Error> => {
  return useQuery(
    ["items", search],
    async () => getStacItems(search, appConfig),
    {
      keepPreviousData: true, // Intended to not clear out search results when panning the map
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      enabled: !!search && Boolean(enabled),
    }
  );
};

const getStacItems = async (
  queryParam: IStacFilter | undefined,
  appConfig: { stacRoot: string } // Ensure appConfig is passed
): Promise<IStacSearchResult> => {

  if (typeof queryParam === "undefined") {
    return Promise.reject(new Error("Search filter is undefined"));
  }

  // Use appConfig to construct the URL
  const resp = await pcApiClient.post(`${appConfig.stacRoot}/search`, queryParam);

  return resp.data;
};
